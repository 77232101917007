import React from 'react';
import PropTypes from 'prop-types';

import 'normalize.css';
import '../styles/tiny-slider.scss';
import '../styles/master.scss';
import Header from './Header';
import Footer from './Footer';
// import Gleap from 'gleap';
import { Helmet } from 'react-helmet';

const Layout = ({ children, headerHero = false, togglePopup = () => {} }) => {
  // useEffect(() => {
  //   // Please make sure to call this method only once!
  //   Gleap.initialize('YigmAdgCPYEPFYWfn9uMPCUwGW3bwBpK');
  // }, []);
  return (
    <>
      <Helmet>
        <link rel="preconnect" href="https://fonts.gstatic.com" />
        <link
          href="https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap"
          rel="stylesheet"
        />
        <link
          href="https://fonts.googleapis.com/css2?family=Manrope:wght@600;700;800&display=swap"
          rel="stylesheet"
        />
      </Helmet>
      <div>
        <Header headerHero={headerHero} togglePopup={togglePopup} />
        <>{children}</>
        <Footer togglePopup={togglePopup} />
      </div>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  headerHero: PropTypes.bool,
  togglePopup: PropTypes.func,
};

export default Layout;
