import { Link } from 'gatsby';
import React from 'react';
import './footer.scss';

function Footer({ togglePopup }) {
  return (
    <footer className="bg-black">
      <div className="container">
        <div className="border-bottom">
          <div className="row ">
            <div className="col col-5">
              <div className="flex flex-center-desk flex-col-mob">
                <div className="footer-logo">
                  <img src="/img/brutask-logo-black.svg" alt="brutask-logo" />
                </div>
                <p className="footer-content">
                  Brutask is the simplest to-do list for small remote teams, who
                  want to increase efficiency by increasing individual
                  productivity.{' '}
                </p>
              </div>
            </div>
            <div className="col col-2 offset-5">
              <ul className="footer-nav-block">
                <li className="footer-nav">
                  <Link to="/about-us">About us</Link>
                </li>
                <li className="footer-nav">
                  <Link to="/contact-us">Contact Us</Link>
                </li>
                <li className="footer-nav">
                  <button type="button" onClick={() => togglePopup('spaces')}>
                    Spaces
                  </button>
                </li>
                <li className="footer-nav">
                  <button type="button" onClick={() => togglePopup('pricing')}>
                    Pricing
                  </button>
                </li>
                <li className="footer-nav">
                  <a
                    href="https://medium.com/brutaskapp"
                    target="_blank"
                    rel="noopener noreferrer">
                    Blog
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col col-3">
            <div>
              <span className="copyright ">
                © Brutask 2023 All rights reserved
              </span>
            </div>
          </div>
          <div className="col col-5">
            <div className="footer-bottom">
              <span>
                <Link to="/terms-and-conditions">Terms &amp; conditions</Link>
              </span>
              <span>
                <Link to="/privacy-policy">Privacy policy</Link>
              </span>
              <span>
                <Link to="/cancellation-and-refund-policy">Refund Policy</Link>
              </span>
            </div>
          </div>
          <div className="col col-4">
            <div className="footer-social">
              <a
                href="https://www.instagram.com/brutask/"
                target="_blank"
                rel="noopener noreferrer">
                <img src="/img/instagram.svg" alt="" />
              </a>
              <a
                href="https://twitter.com/brutaskapp"
                target="_blank"
                rel="noopener noreferrer">
                <img src="/img/twitter.svg" alt="" />
              </a>
              <a
                href="https://www.linkedin.com/company/brutask/"
                target="_blank"
                rel="noopener noreferrer">
                <img src="/img/linkedin.svg" alt="" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
