import React, { useEffect, useCallback, useState, useRef } from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import './header.scss';

function Header({ headerHero, togglePopup }) {
  const [mobileMenu, setMobileMenu] = useState(false);
  const [goingUp, setGoingUp] = useState(true);
  const prevScrollY = useRef(0);
  const headerLogo = useRef(null);
  const [showTopbar, setShowTopbar] = useState(false);
  const [isSticky, setSticky] = useState(true);

  const handleScroll = useCallback(() => {
    const currentScrollY = window.scrollY;
    if (
      window.pageYOffset > 100 &&
      prevScrollY.current < currentScrollY &&
      goingUp
    ) {
      setGoingUp(false);
    }
    if (prevScrollY.current > currentScrollY && !goingUp) {
      setGoingUp(true);
    }
    prevScrollY.current = currentScrollY;
    ///header logo show
    headerLogo.current.classList.toggle(
      'hide',
      window.pageYOffset < 200 && window.innerWidth > 767 && headerHero
    );
    ////top-bar
    // setSticky(window.pageYOffset > 36);
  }, [goingUp]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });
    return () => window.removeEventListener('scroll', handleScroll);
  }, [goingUp]);

  const mobileMenuClick = useCallback((e) => {
    if ((e.type === 'keydown' && e.key === 'Enter') || e.type === 'click')
      setMobileMenu((state) => !state);
  }, []);

  useEffect(() => {
    document.body.style.overflow = mobileMenu ? 'hidden' : 'visible';
  }, [mobileMenu]);

  return (
    <>
      {showTopbar && (
        <div
          className="slack-community"
          style={{
            backgroundColor: 'rgb(22, 6, 71)',
          }}
        >
          <div className="container">
            <div className="slack-content">
              {/* <span className="slack-icon">
              <img src="/assets/img/app-sumo.png" alt="app-sumo" />
            </span> */}
              <p style={{ color: '#fff' }}>
                <img
                  class="hidden-mobile"
                  src="/img/ruttl-logo.svg"
                  alt="ruttl is here"
                />
                Get flat
                <span className="yellow bold">&nbsp;40% off</span>
                !&nbsp;on our partner tool ruttl’s yearly and lifetime privilege
                plans.&nbsp;
                <a
                  className="yellow"
                  href="https://ruttl.com/privilege-plans/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Know more
                </a>
              </p>
              {/* <div className="slack-hover">
              <p style={{ fontWeight: '600', marginBottom: '4px' }}>
                Why Community?
              </p>
              <p>
                The purpose is to stay connected and build our tool as per
                your needs.
              </p>
            </div> */}
            </div>
          </div>
        </div>
      )}
      <header>
        <div
          className={`header ${goingUp ? '' : 'nav-hidden'} ${
            mobileMenu ? 'nav-open' : ''
          } ${isSticky ? 'nav-sticky' : ''}`}
        >
          <div className="container">
            <div className="nav">
              <div className="flex justify-space-between">
                <div className="nav-left">
                  <Link
                    className="nav-link about-us visible-mobile"
                    to="/about-us"
                  >
                    About us
                  </Link>
                  <button
                    className="nav-link tag"
                    data-value="Beta"
                    type="button"
                    onClick={() => togglePopup('spaces')}
                  >
                    <div className="spaces-icon hidden-mobile">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="#ffffff"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        <path d="M21 16V8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16z"></path>
                        <polyline points="3.27 6.96 12 12.01 20.73 6.96"></polyline>
                        <line x1="12" y1="22.08" x2="12" y2="12"></line>
                      </svg>
                    </div>
                    Spaces
                  </button>
                  <button
                    className="nav-link"
                    type="button"
                    onClick={() => togglePopup('pricing')}
                  >
                    Pricing
                  </button>
                </div>
                <a
                  href="/"
                  className={`logo ${headerHero ? 'hide' : ''}`}
                  ref={headerLogo}
                >
                  <img src="/img/brutask-logo.svg" alt="" />
                </a>
                <div className="nav-right">
                  <a
                    className="nav-link"
                    href="https://medium.com/brutaskapp"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Blog
                  </a>
                  <a
                    href="https://app.brutask.com/"
                    type="button"
                    className="button button-transparent button-round"
                  >
                    Login
                  </a>
                </div>
              </div>
            </div>
            <div className="flex justify-space-between align-center">
              <a
                className="nav-link tag visible-mobile"
                data-value="Beta"
                type="button"
                onClick={() => togglePopup('spaces')}
              >
                <div className="spaces-icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="feather feather-box dark:text-neutral-200"
                  >
                    <path d="M21 16V8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16z"></path>
                    <polyline points="3.27 6.96 12 12.01 20.73 6.96"></polyline>
                    <line x1="12" y1="22.08" x2="12" y2="12"></line>
                  </svg>
                </div>
                Spaces
              </a>
              <span
                className="hamburger"
                role="button"
                tabIndex="0"
                onClick={mobileMenuClick}
                onKeyDown={mobileMenuClick}
              >
                <svg
                  version="1.1"
                  id="Layer_1"
                  xmlns="http://www.w3.org/2000/svg"
                  x="0px"
                  y="0px"
                  viewBox="0 0 41 36"
                  style={{ enableBackground: 'new 0 0 41 36' }}
                >
                  <line
                    className="hamburger-line"
                    x1="2"
                    y1="2"
                    x2="39"
                    y2="2"
                  />
                  <line
                    className="hamburger-line"
                    x1="16"
                    y1="18"
                    x2="39"
                    y2="18"
                  />
                  <line
                    className="hamburger-line"
                    x1="2"
                    y1="34"
                    x2="39"
                    y2="34"
                  />
                </svg>
              </span>
            </div>
          </div>
        </div>
      </header>
      {isSticky && <div style={{ height: 75 }} />}
    </>
  );
}

Header.propTypes = {
  headerHero: PropTypes.bool,
};

export default Header;
